body {
  background-color: orange;
  padding-bottom: 3rem;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif;
}

html, body {
  overflow: hidden;
  margin: 0 !important;
  padding: 0 !important;
}

canvas {
  background-color: #fff;
}

/*# sourceMappingURL=index.fba973a6.css.map */
